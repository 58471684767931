import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const QnA = () => {
  const location = useLocation();
  const navbarHeight = 80; // Adjust this value based on the height of your sticky navbar

  // Smooth scroll to the relevant section when the URL hash changes
  useEffect(() => {
    const section = document.getElementById(location.hash.replace("#", ""));
    if (section) {
      window.scrollTo({
        top: section.offsetTop - navbarHeight, // Offset by the navbar height
        behavior: "smooth",
      });
    }
  }, [location]);

  return (
    <div className="min-h-screen  text-gray-800 p-6 pt-[80px] md:pt-[100px] relative overflow-hidden">
      {/* Background Animation */}
    

      <div className="max-w-5xl mx-auto relative z-10">
        <h1 className="text-4xl font-bold text-center text-blue-600 mb-12">
          How to increase sale ROI
        </h1>
        <div className="space-y-12">
          {/* Question 1 */}
          {/* <div
            id="question-1"
            className="p-6 bg-white shadow-lg rounded-lg border border-gray-200 transition-transform transform hover:scale-105 duration-300"
          >
            <h2 className="text-3xl font-semibold mb-4 text-blue-700">
              How to Build a High-Performance Sales Team?
            </h2>
            <ol className="list-decimal pl-6 space-y-4 text-gray-700">
              <li>
                Hire smart sales executives; paying higher for talented ones is
                a no-brainer.
              </li>
              <li>Give them advanced CRM tools like RealtorApp.io.</li>
              <li>
                Measure their efforts and performance on RealtorApp.io.
                Performance should only be measured in:
                <ul className="list-disc pl-6 space-y-2">
                  <li>No. of Deals Closed</li>
                  <li>Brokerage Created</li>
                </ul>
                You can also track effort metrics like:
                <ul className="list-disc pl-6 space-y-2">
                  <li>No. of Site Visits</li>
                  <li>No. of Calls</li>
                </ul>
              </li>
              <li>Identify weak performers and analyze their efforts.</li>
              <li>
                Help them understand their mistakes, encourage them, and offer
                support during tough times.
              </li>
              <li>
                Monitor their site visits, follow-up calls, and performance.
                Counsel them again as needed.
              </li>
              <li>
                If no improvements are seen, part ways without hesitation.
              </li>
              <li>Repeat the process to continuously refine your team.</li>
            </ol>
          </div> */}
          <div
  id="question-1"
  className="relative p-6 rounded-lg shadow-md border border-gray-300 transition-transform transform hover:scale-105 duration-300 bg-white overflow-hidden"
>
  {/* Decorative Background */}
  <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-blue-100 opacity-70 pointer-events-none"></div>

  {/* Geometric Shapes */}
  <div className="absolute -top-10 -right-10 w-32 h-24 bg-yellow-300 rounded-full opacity-20 square-animate"></div>
  <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-indigo-300 rounded-full opacity-30 shape-animate"></div>
  <div className="absolute bottom-20 left-2/3 w-32 h-32 bg-pink-200 opacity-30 rotate-45 transform rounded-md square-animate  "></div>
  {/* <div className="absolute bottom-12 right-12 w-20 h-20 bg-blue-400 opacity-50 rotate-12 transform rounded-md square-animate "></div> */}

  <div className="absolute top-4 right-4 text-blue-300 opacity-50 text-6xl">
    <i className="fas fa-lightbulb"></i>
  </div>

  {/* Content */}
  <h2 className="relative text-2xl font-semibold mb-4 text-blue-700">
    How to Build a High-Performance Sales Team?
  </h2>
  <ol className="list-decimal pl-6 space-y-4 text-gray-700 relative">
    <li>
      Hire smart sales executives; paying higher for talented ones is a no-brainer.
    </li>
    <li>Give them advanced CRM tools like RealtorApp.io.</li>
    <li>
      Measure their efforts and performance on RealtorApp.io. Performance should only be measured in:
      <ul className="list-disc pl-6 space-y-2">
        <li>No. of Deals Closed</li>
        <li>Brokerage Created</li>
      </ul>
      You can also track effort metrics like:
      <ul className="list-disc pl-6 space-y-2">
        <li>No. of Site Visits</li>
        <li>No. of Calls</li>
      </ul>
    </li>
    <li>Identify weak performers and analyze their efforts.</li>
    <li>
      Help them understand their mistakes, encourage them, and offer support during tough times.
    </li>
    <li>
      Monitor their site visits, follow-up calls, and performance. Counsel them again as needed.
    </li>
    <li>
      If no improvements are seen, part ways without hesitation.
    </li>
    <li>Repeat the process to continuously refine your team.</li>
  </ol>
</div>

          {/* Question 2 */}
          <div
  id="question-2"
  className="relative p-6 rounded-lg shadow-lg border border-gray-200 transition-transform transform hover:scale-105 duration-300 bg-white overflow-hidden"
>
  {/* Decorative Background */}
  <div className="absolute inset-0 bg-gradient-to-bl from-gray-50 to-green-50 opacity-80 pointer-events-none"></div>

  {/* Geometric Shapes */}
  <div className="absolute -top-8 -left-8 w-20 h-24 bg-teal-300 rounded-full opacity-25 shape-animate"></div>
  <div className="absolute top-1/4 -right-12 w-36 h-36 bg-orange-300 rounded-full opacity-30"></div>
  <div className="absolute -bottom-8 left-1/4 w-28 h-28 bg-pink-200 opacity-40 rotate-45 transform rounded-md square-animate "></div>

  <div className="absolute top-4 left-4 text-teal-300 opacity-60 text-5xl">
    <i className="fas fa-chart-line"></i>
  </div>

  {/* Content */}
  <h2 className="relative text-3xl font-semibold mb-4 text-teal-700">
    Investing in Property Portals, Newspapers, Social Media?
  </h2>
  <p className="mb-6 relative text-green-900">
    Not sure where to invest and which source is best for you?
  </p>
  <ol className="list-decimal pl-6 space-y-4 text-gray-700 relative">
    <li>
      Add the source while creating inquiries. AI-generated inquiries
      will include sources by default.
    </li>
    <li>
      Track inquiry sources, deals, and results over time on
      RealtorApp.io.
    </li>
    <li>
      Analyze why certain sources perform better or worse:
      <ul className="list-disc pl-6 space-y-2">
        <li>Are you investing or posting ads consistently?</li>
        <li>Are your ad quality and targeting effective?</li>
        <li>How do competitors view that source?</li>
        <li>Are you giving attention to leads from that source?</li>
        <li>Is the platform suitable for your business?</li>
      </ul>
      With consistent evaluation, determine where to invest and where
      to cut back.
    </li>
  </ol>
</div>


          {/* Question 3 */}
          <div
  id="question-3"
  className="relative p-6 rounded-lg shadow-lg border border-gray-200 transition-transform transform hover:scale-105 duration-300 bg-white overflow-hidden"
>
  {/* Decorative Background */}
  <div className="absolute inset-0 bg-gradient-to-tl from-indigo-50 to-indigo-100 opacity-80 pointer-events-none"></div>

  {/* Geometric Shapes with Animation */}
  <div className="absolute -top-12 -left-12 w-28 h-28 bg-indigo-300 rounded-full opacity-20 shape-animate"></div>
  <div className="absolute top-1/3 right-0 w-40 h-40 bg-green-300 rounded-full opacity-30 shape-animate"></div>
  <div className="absolute -top-10 left-1/2 w-36 h-36 bg-yellow-200 opacity-40 rotate-45 transform rounded-md "></div>

  <div className="absolute top-6 left-6 text-indigo-300 opacity-60 text-5xl">
    <i className="fas fa-search-dollar"></i>
  </div>

  {/* Content */}
  <h2 className="relative text-3xl font-semibold mb-4 text-indigo-700">
    Analyzing Sources for Better Investment Decisions
  </h2>
  <p className="mb-6 relative text-indigo-800">
    Detailed analysis of sources helps optimize investments.
  </p>
  <ol className="list-decimal pl-6 space-y-4 text-gray-700 relative">
    <li>Track the source of each inquiry and its outcomes.</li>
    <li>
      Regularly analyze sources and adjust your strategy for better
      ROI.
    </li>
  </ol>
</div>

<style jsx>{`
  /* Keyframes for animating shapes */
  @keyframes shapeMovement {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(15px, 15px);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  /* Keyframes for rotating the square */
  @keyframes rotateSquare {
    0% {
      transform: rotate(45deg);
    }
      25% {
      transform: rotate(-135deg);
    }
    50% {
      transform: rotate(135deg);
    }
      75% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(-90deg);
    }
  }

  /* Apply animation to the shapes */
  .shape-animate {
    animation: shapeMovement 10s infinite ease-in-out;
  }

  /* Apply rotation animation to the square */
  .square-animate {
    animation: rotateSquare 20s infinite linear;
  }

  /* Optional hover effect to make them move when hovered */
  #question-3:hover .shape-animate {
    animation: shapeMovement 20s infinite ease-in-out;
  }
`}</style>


        </div>
      </div>
    </div>
  );
};

export default QnA;
