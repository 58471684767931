// propertySlice.js

import {  createSlice } from '@reduxjs/toolkit';

const initialState = {
  properties: {},
  filteredProperties:{},
  inquiries:{},
  filteredInquiries: {}
}

export const propertySlice = createSlice({
  name: 'property', 
  initialState,
  reducers: {
    
    setProperty: (state, action) => {
      state.properties = action.payload;
    },
    setFilteredProperties: (state, action) => {
      state.filteredProperties = action.payload;
    },
    setInquiry: (state, action) => {
      state.inquiries = action.payload;
    },
    setFilteredInquiries: (state, action) => {
      state.filteredInquiries = action.payload;
    },
    clearProperty: () => initialState,
   
  },
 
})

export const {setProperty,setInquiry,setFilteredInquiries, setFilteredProperties, clearProperty} = propertySlice.actions

export default propertySlice.reducer;
