// import React, { useEffect, useState } from "react";
// import { FaCheck, FaExclamation } from "react-icons/fa";
// import {  useParams } from "react-router-dom";

// const PaymentStatus = () => {
//   const {status}  = useParams()
//   const [paymentStatus, setPaymentStatus] = useState(null);

//   useEffect(() => {
//     setPaymentStatus(status);
//   }, [status]);

//   return (
//     <div className="bg-gradient-to-r from-gray-50 to-gray-100 flex justify-center items-center min-h-screen">
//       <div className="bg-white p-6 rounded-xl shadow-md md:w-1/2 md:h-1/2 text-center">
//         <h1 className="text-2xl font-bold text-gray-900 mb-5">Payment Status</h1>

//         {paymentStatus === "success" ? (
//           <div className="bg-gradient-to-r from-green-100 to-green-200 p-4 rounded-xl">
//              <div className="flex justify-center  my-8">
//       <div className="relative flex items-center justify-center w-16 h-16 bg-green-600 text-white rounded-full">
//         <span className="text-2xl font-bold"><FaCheck/></span>
//       </div>
//     </div>
//             <h2 className="text-4xl font-semibold text-green-600 mb-5">Payment Successful!</h2>
//             <p className="mt-2 text-gray-700 text-xl">Your account has been activated.</p>
//           </div>
//         ) : paymentStatus === "failed" ? (
//           <div className="bg-gradient-to-r from-red-100 to-red-200 p-4 rounded-xl">
//             <div className="flex justify-center my-8">
//       <div className="relative flex items-center justify-center w-16 h-16 bg-red-600 text-white rounded-full">
//         <span className="text-2xl font-bold"><FaExclamation/> </span>
//       </div>
//     </div>
//             <h2 className="text-4xl mb-5 font-semibold text-red-600">Payment Failed</h2>
//             <p className="mt-2 text-gray-700">Please try again.</p>
//           </div>
//         ) : (
//           <div className="bg-gradient-to-r from-blue-100 to-blue-200 p-4 rounded-xl flex items-center justify-center flex-col">
            
//              <div className=" hover:text-white transition-all px-4 py-2 my-5 rounded-md ">
//                   <div className="text-center  border-gray-400 h-16 w-16 animate-spin rounded-full border-8 border-t-blue-600" />
//               </div>
//             <h2 className="text-4xl font-semibold text-blue-600 mb-5">Processing...</h2>
//             <p className="mt-2 text-gray-700 text-lg mb-5">Please wait while we confirm your payment status.</p>
//           </div>
//         )}

//         <div className="mt-5">
//           {paymentStatus === "failed" && (
//             <button
//               className="px-6 py-3 bg-gradient-to-r from-red-400 to-red-500 text-white font-bold rounded-lg shadow hover:from-red-500 hover:to-red-600"
//               onClick={() => window.history.back()}
//             >
//               Try Again
//             </button>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PaymentStatus;




import React, { useEffect, useState } from "react";
import { FaCheck, FaExclamation } from "react-icons/fa";
// import { useParams } from "react-router-dom";
import {  getApiRequest } from "../../util/Api";

const PaymentStatus = () => {
  // const { status } = useParams();
  const [paymentStatus, setPaymentStatus] = useState(null);

  // useEffect(() => {
  //   setPaymentStatus(status);
  // }, [status]);
  const [loading, setLoading] = useState(true); // Track API call loading state

  useEffect(() => {
    // Simulate a backend call to verify the status
    const verifyPaymentStatus = async () => {
      try {
        setLoading(true);
        // Replace this with an actual API call
        const data = await getApiRequest(`/paisaa/trx_payment_status/`);

        setPaymentStatus(data?.results[0]?.transaction_status);
      } catch (error) {
        console.error("Error verifying payment:", error);
        setPaymentStatus("failed");
      } finally {
        setLoading(false);
      }
    };

    verifyPaymentStatus();
  }, []);


  if (loading) {
    return (
      <div className="bg-gradient-to-r from-gray-50 to-gray-100 flex justify-center items-center min-h-screen">
      <div className="bg-white p-6 rounded-xl shadow-md md:w-1/2 md:h-1/2 text-center">
        <h1 className="text-2xl font-bold text-gray-900 mb-5">
          Payment Status
        </h1>
      <div className="bg-gradient-to-r from-blue-100 to-blue-200 p-4 rounded-xl flex items-center justify-center flex-col">
            <div className=" hover:text-white transition-all px-4 py-2 my-5 rounded-md ">
              <div className="text-center  border-gray-400 h-16 w-16 animate-spin rounded-full border-8 border-t-blue-600" />
            </div>
            <h2 className="text-4xl font-semibold text-blue-600 mb-5">
              Processing...
            </h2>
            <p className="mt-2 text-gray-700 text-lg mb-5">
              Please wait while we confirm your payment status.
            </p>
          </div>
          </div>
          </div>
    );
  }
  return (
    <div className="bg-gradient-to-r from-gray-50 to-gray-100 flex justify-center items-center min-h-screen">
      <div className="bg-white p-6 rounded-xl shadow-md md:w-1/2 md:h-1/2 text-center">
        <h1 className="text-2xl font-bold text-gray-900 mb-5">
          Payment Status
        </h1>

        {paymentStatus === "SUCCESS" ? (
          <div className="bg-gradient-to-r from-green-100 to-green-200 p-4 rounded-xl">
            <div className="flex justify-center  my-8">
              <div className="relative flex items-center justify-center w-16 h-16 bg-green-600 text-white rounded-full">
                <span className="text-2xl font-bold">
                  <FaCheck />
                </span>
              </div>
            </div>
            <h2 className="text-4xl font-semibold text-green-600 mb-5">
              Payment Successful!
            </h2>
            <p className="mt-2 text-gray-700 text-xl">
              Your account has been activated.
            </p>
          </div>
        ) : paymentStatus === "FAILED" ? (
          <div className="bg-gradient-to-r from-red-100 to-red-200 p-4 rounded-xl">
            <div className="flex justify-center my-8">
              <div className="relative flex items-center justify-center w-16 h-16 bg-red-600 text-white rounded-full">
                <span className="text-2xl font-bold">
                  <FaExclamation />{" "}
                </span>
              </div>
            </div>
            <h2 className="text-4xl mb-5 font-semibold text-red-600">
              Payment Failed
            </h2>
            <p className="mt-2 text-gray-700">Please try again.</p>
          </div>
        ) : (
          <div className="bg-gradient-to-r from-blue-100 to-blue-200 p-4 rounded-xl flex items-center justify-center flex-col">
            <div className=" hover:text-white transition-all px-4 py-2 my-5 rounded-md ">
              <div className="text-center  border-gray-400 h-16 w-16 animate-spin rounded-full border-8 border-t-blue-600" />
            </div>
            <h2 className="text-4xl font-semibold text-blue-600 mb-5">
              Processing...
            </h2>
            <p className="mt-2 text-gray-700 text-lg mb-5">
              Please wait while we confirm your payment status.
            </p>
          </div>
        )}

        <div className="mt-5">
          {paymentStatus === "failed" && (
            <button
              className="px-6 py-3 bg-gradient-to-r from-red-400 to-red-500 text-white font-bold rounded-lg shadow hover:from-red-500 hover:to-red-600"
              onClick={() => window.history.back()}
            >
              Try Again
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentStatus;
