import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
// import { IoClose } from "react-icons/io5";

const Sidebar = ({ isMobileMenuOpen, setMobileMenuOpen }) => {
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setMobileMenuOpen(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setMobileMenuOpen]);

  return (
    <div>
      <div
        ref={sidebarRef}
        className={`md:hidden fixed top-0 left-0 bg-gray-50 text-black text-2xl  p-3 h-full w-[75vw] z-50 transition-transform duration-300 ${
          isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <Link
          to="/"
          className="px-3 py-2 block md:hidden font-medium"
          onClick={() => setMobileMenuOpen(false)}
        >
          Home
        </Link>
        <Link
          to="/dashboard"
          className="px-3 py-2 block md:hidden font-medium"
          onClick={() => setMobileMenuOpen(false)}
        >
          Dashboard
        </Link>
        <Link
          to="/searchProperty"
          className="px-3 py-2 block md:hidden font-medium"
          onClick={() => setMobileMenuOpen(false)}
        >
          Properties
        </Link>
        <Link
          to="/projects"
          className="px-3 py-2 block md:hidden font-medium"
          onClick={() => setMobileMenuOpen(false)}
        >
          Projects
        </Link>
        <Link
          to="/searchInquiry"
          className="px-3 py-2 block md:hidden font-medium"
          onClick={() => setMobileMenuOpen(false)}
        >
          Inquiries
        </Link>
        <Link
          to="/contact"
          className="px-3 py-2 block md:hidden font-medium"
          onClick={() => setMobileMenuOpen(false)}
        >
          Contacts
        </Link>
        <Link
          to="/loans"
          className="px-3 py-2 block md:hidden font-medium"
          onClick={() => setMobileMenuOpen(false)}
        >
          Loans
        </Link>
        <Link
          to="/viewProperty"
          className="px-3 py-2 block md:hidden font-medium"
          onClick={() => setMobileMenuOpen(false)}
        >
          Viewed Properties
        </Link>
        <Link
          to="/viewInquiry"
          className="px-3 py-2 block md:hidden font-medium"
          onClick={() => setMobileMenuOpen(false)}
        >
          Viewed Inquiries
        </Link>
        {/* <IoClose
          className="absolute right-4 top-4 w-10 h-10 cursor-pointer"
          onClick={() => setMobileMenuOpen(false)}
        /> */}
      </div>
    </div>
  );
};

export default Sidebar;
