import React from "react";
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="md:px-10 md:py-14 px-5 text-center   bg-[rgb(30,157,228)]  text-white  mt-6">
      <div className="grid md:grid-cols-4 items-center    pb-5  gap-5 justify-center ">
        <div className="flex flex-col item md:items-start">
          <img src="/images/appLogo.png" alt="" className="w-28" />
          <div className="text-start flex flex-col md:gap-y-5 gap-y-2">
            <p className="text-xl font-bold">Follow us On:</p>
            <div className="flex gap-2">
              <Link
                to="https://www.facebook.com/profile.php?id=61561011920640"
                target="blank"
              >
                <span className="w-10 h-10 bg-white cursor-pointer social-icon transition-all rounded-[50%]   flex items-center justify-center ">
                  <FaFacebook className="w-6 h-6 text-blue-500 " />
                </span>
              </Link>
              <Link
                to="https://www.instagram.com/realtorapp.io/"
                target="blank"
              >
                <span className="w-10 h-10 bg-white cursor-pointer social-icon transition-all rounded-[50%]   flex items-center justify-center ">
                  <FaInstagram className="w-6 h-6 text-red-500" />
                </span>
              </Link>
              <Link to="https://x.com/RealtorApp2024" target="blank">
                <span className="w-10 h-10 bg-white cursor-pointer social-icon transition-all rounded-[50%]   flex items-center justify-center ">
                  <FaSquareXTwitter className="w-6 h-6 text-black" />
                </span>
              </Link>

              <Link
                to="https://web.whatsapp.com/send?phone=919327055500"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-800"
              >
                <span className=" social-icon bg-white w-10 h-10 cursor-pointer  transition-all rounded-[50%]   flex items-center justify-center ">
                  <FaWhatsapp className="w-6 h-6 text-green-500" />
                </span>
              </Link>

              <Link to="https://www.youtube.com/@Realtor_App" target="blank">
                <span className="w-10 h-10 bg-white cursor-pointer social-icon transition-all rounded-[50%]   flex items-center justify-center ">
                  <FaYoutube className="w-6 h-6 text-red-500" />
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex  justify-evenly gap-10 col-span-2">
        <div className="flex flex-col  items-start gap-2 ">
          <p className="md:text-2xl text-lg font-bold pb-3">Quick Links</p>
          <Link
            to="/"
            onClick={scrollToTop}
            className="relative group flex gap-2 items-center"
          >
            Home
            <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-white transition-all duration-300 group-hover:w-full"></span>
          </Link>

          <Link
            to="/offers"
            onClick={scrollToTop}
            className="relative group flex gap-2 items-center"
          >
            Pricing
            <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-white transition-all duration-300 group-hover:w-full"></span>
          </Link>
          <Link
            to="/policy/PrivacyPolicy"
            onClick={scrollToTop}
            className="relative group flex gap-2 items-center"
          >
            Privacy Policy
            <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-white transition-all duration-300 group-hover:w-full"></span>
          </Link>
          <Link
            to="/policy/RefundPolicy"
            onClick={scrollToTop}
            className="relative group flex gap-2 items-center"
          >
            Refund Policy
            <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-white transition-all duration-300 group-hover:w-full"></span>
          </Link>
          <Link
            to="/policy/Terms&Conditions"
            onClick={scrollToTop}
            className="relative group flex gap-2 items-center"
          >
            Terms & Conditions
            <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-white transition-all duration-300 group-hover:w-full"></span>
          </Link>
        </div>
        <div className="flex flex-col  items-start gap-2 ">
          <p className="md:text-2xl text-lg font-bold pb-3">Get In Touch</p>
          <Link
            to="/support"
            onClick={scrollToTop}
            className="relative group flex gap-2 items-center"
          >
            SUPPORT
            <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-white transition-all duration-300 group-hover:w-full"></span>
          </Link>
          {/* <span className="text-start">Innovsense Technologies</span>
          <span className="text-start">S/2, Second Floor, Olway House</span>
          <span className="text-start">Nr Navneet Press, </span>
          <span className="text-start">Gurukul Road, Ahmedabad</span> */}

          <p>Call us : +91 9327055500 </p>
          <p className="flex">
            Whatsapp :{" "}
            <a
              href="https://web.whatsapp.com/send?phone=916356424934"
              target="_blank"
              rel="noopener noreferrer"
              className="relative group flex gap-2 items-center"
            >
              +91 9327055500
              <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-white transition-all duration-300 group-hover:w-full"></span>
            </a>{" "}
          </p>
          <p>Email : support@realtorapp.io</p>
        </div>
        </div>
        <div className="flex flex-col  items-start gap-2 font-medium">
          <p className="text-xl font-bold">App Coming Soon On</p>
          <div className="flex md:flex-col gap-2">
            <div className="bg-black rounded-xl p-2 border shadow-sm shadow-black text-white flex items-center justify-evenly">
              <img src="/images/android.png" alt="" className="md:w-10 w-10" />
              <div>
                <p className="text-[10px]">Android app On</p>
                <p className="md:text-lg md:font-semibold ">Google Play</p>
              </div>
            </div>
            <div className="bg-black rounded-xl p-2 border shadow-sm shadow-black text-white flex items-center justify-evenly">
              <img src="/images/ios1.png" alt="" className="w-10" />
              <div>
                <p className="text-[10px]">Download on the</p>
                <p className="md:text-lg md:font-semibold">Apple store</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
