import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoCall, IoLogOutSharp } from "react-icons/io5";
import {
  MdDevices,
  MdDone,
  MdManageAccounts,
  MdOutlineContactPhone,
} from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  getUserCompanyInfo,
  getUserInfo,
  isLoggedIn,
  loginSuccess,
} from "../../redux/Slices/authSlice";
import { BASE_URL } from "../../util/Api";
import {
  FaBell,
  FaChartLine,
  FaExchangeAlt,
  FaFilter,
  FaLock,
  FaMoneyBillAlt,
  FaShare,
  FaUserCheck,
  FaUserClock,
} from "react-icons/fa";
import MainSlider from "./MainSlider";

const Welcome = () => {
  // const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchData = async (token) => {
    try {
      if (token) {
        const response = await fetch(`${BASE_URL}/auth/users/me/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        });

        const result = await response.json();
        if (response.ok) {
          dispatch(isLoggedIn());
          dispatch(getUserInfo(result));

          // Fetch user profile photo
          const userProfileResponse = await fetch(
            `${BASE_URL}/properties/account/me`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          );

          const userProfileData = await userProfileResponse.json();
          if (userProfileResponse.ok) {
            dispatch(getUserCompanyInfo(userProfileData));
            // setUserCompanyData(userProfileData);
          } else {
            console.log("Error fetching user profile photo:", userProfileData);
          }
        } else {
          console.log("Error during login:", result);
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/auth/jwt/create/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: "Akhil", password: "Sunday@4321" }),
      });
      const result = await response.json();
      // setIsLoading(false)
      if (response.ok) {
        dispatch(loginSuccess(result));
        fetchData(result.access);
        // localStorage.setItem("token", result.access);
        navigate("/");
      } else {
        console.log("Error during login :", result);
        // setErrMessage(result);
      }
    } catch (error) {
      console.error("Error during logged In  ");
      // setIsLoading(false)
    }
  };

  return (
    <div className="-mb-6 border-b-[1px] border-black welcome">
      <div className="px-2 py-2 md:px-10 md:py-3 bg-gray-5 flex items-center justify-between bg-[#1E9DE4] text-white fixed w-full top-0 z-20">
        <Link to="/" className="flex items-center gap-1">
          <img
            src="/images/appLogo.png"
            alt=""
            className=" h-10 w-10 md:w-12 md:h-12 scale-150"
          />
          <span className="hidden md:flex md:ml-5 ml-0 font-medium md:text-xl text-xl">
            RealtorApp.io
          </span>
        </Link>

        <div className="flex md:gap-5  gap-1 items-center">
          <Link
            to={"/offers"}
            className=" h-fit flex md:gap-3  gap-1 text-[10px] items-center cursor-pointer uppercase  shadow-black  text-black bg-gradient-to-br from-yellow-50 to-yellow-200 md:px-4 px-2 py-2   rounded-full font-semibold md:text-sm shadow-sm hover:bg-[#FFC107] transition-transform transform "
          >
            Pricing
          </Link>

          <a
            href="tel:+919327055500"
            className="md:hidden flex items-center justify-center p-2 rounded-md  text-white"
          >
            <IoCall className="w-6 h-6" />
          </a>
          <p className="hidden md:flex items-center gap-3  p-2 rounded-xl text-white">
            <span>
              <IoCall className="w-6 h-6" />
            </span>
            <span className="text-lg font-semibold">+91 9327055500</span>
          </p>
          <Link
            to="/login"
            className="font-medium uppercase p-2 md:px-3 border-2 border-white hover:text-black  hover:bg-white  md:rounded-xl rounded-sm  transition-all"
          >
            Login
          </Link>
          <Link
            to="/register"
            className="font-medium uppercase p-2 md:px-3 border-2 border-white hover:text-black  hover:bg-white  md:rounded-xl rounded-sm  transition-all"
          >
            Sign Up
          </Link>
        </div>
      </div>
      <div className=" pt-[70px]   bg-[#e3f9fa] ">
        
       <MainSlider handleSubmit={handleSubmit} />

        <div
          className=" md:h-[700px]   relative bg-white"
          style={{
            backgroundImage: "url('/images/buildings.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="flex  justify-center md:gap-10 gap-5 flex-col md:flex-row  px-5  md:pb-10"></div>
          <div className="w-full  text-center md:pt-20 pt-5 pb-20 ">
            <p className="text-red-600 text-xl  pb-5 font-bold">
              POWERFUL FEATURES TO HELP YOU
            </p>
            <h1 className="md:text-5xl text-3xl font-bold ">
              <p>Built with features for</p> <p>growing businesses</p>
            </h1>
          </div>
        </div>
        <div className="md:px-32 px-5  relative   bg-white">
          <div className="flex flex-col relative md:-top-80 -top-10  gap-10 items-center ">
            <div className="bg-[#c4e6fa]   = shadow-black md:shadow-lg shadow-md text-gray-800 md:px-10 px-5 py-5 flex flex-col md:flex-row  rounded-xl w-full">
              <div className="flex flex-col md:gap-5 gap-3">
                <h1 className="md:text-2xl text-lg font-semibold">
                  Manage Properties - Rent/Sale, Residential & Commercial
                </h1>
                <h2 className="md:text-xl text-base font-semibold">
                  Create Property in less than 30 seconds
                </h2>
                <div className="flex flex-col gap-5 text-md font-semibold">
                  <p className="flex items-center md:gap-5 gap-3">
                    <span>
                      <MdDone className="w-8 h-8 " />
                    </span>
                    Custom fields like Area, Society, Source, Contactgroups etc.
                  </p>
                  <p className="flex items-center md:gap-5 gap-3">
                    <span>
                      <MdDone className="w-8 h-8 " />
                    </span>
                    Store and share Images, Videos, Video Links, Brochures
                  </p>
                  <p className="flex items-center md:gap-5 gap-3">
                    <span>
                      <MdDone className="w-8 h-8 " />
                    </span>
                    Fast Search Results.
                  </p>
                </div>
              </div>
              <div>
                <img src="/images/sale.png" alt="" />
              </div>
            </div>

            {/* [#12306c]  */}

            <div className="flex w-full  md:gap-10 flex-col md:flex-row gap-5 ">
              <div className="bg-[#c4e6fa]  shadow-black md:shadow-lg shadow-md text-gray-800 md:px-10 px-5 pt-5 flex flex-col  gap-5 rounded-xl w-full">
                <h1 className="text-2xl font-semibold">Inquiry Management</h1>
                {/* <h2>Track your stock in/out and profits</h2> */}
                <div className="flex flex-col gap-3 text-md font-semibold">
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8" />
                    </span>{" "}
                    Add inquiries directly from all major prooperty portal
                  </p>
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8" />
                    </span>
                    Assigning single and multiple inquiries to executives
                  </p>
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8" />
                    </span>
                    Add inquiry in less than 25 seconds
                  </p>
                  {/* <p className="flex items-center gap-5">
                    <span>
                      <MdDone className="w-8 h-8 text-white" />
                    </span>
                    Profit tracking at daily, weekly and monthly level
                  </p> */}
                </div>
                <div className="flex justify-end md:-mt-10 -mt-20">
                  <img
                    src="/images/inquiryVector.png"
                    alt=""
                    className="md:w-[300px]  w-[150px] opacity-50 md:opacity-100"
                  />
                </div>
              </div>
              <div className=" bg-[#c4e6fa]   shadow-black  md:shadow-lg shadow-md text-gray-800 flex flex-col gap-5 md:px-10 px-5 pt-5 rounded-xl w-full">
                <h1 className="text-2xl font-semibold">
                  Manage Your Bussiness
                </h1>
                {/* <h2 className="text-xl font-semibold">Track your stock in/out and profits</h2> */}
                <div className="flex flex-col gap-2 text-md font-semibold">
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8 " />
                    </span>
                    Check records who viewd porperty or inquiry
                  </p>
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8 " />
                    </span>
                    Track inquiry status, sitevisits, tasks etc employee wise
                  </p>
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8 " />
                    </span>
                    Prompt/Alerts for upcoming tasks, sitevisits
                  </p>
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8 " />
                    </span>
                    Create or Add data in bulk
                  </p>
                  <p className="flex items-center md:gap-5 gap-2">
                    <span>
                      <MdDone className="w-8 h-8 " />
                    </span>
                    Secure your business data
                  </p>
                </div>
                <div className="flex justify-end -mt-28">
                  <img
                    src="/images/bussinessVector.png"
                    alt=""
                    className="md:w-[300px]  w-[150px] opacity-50 md:opacity-70"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="md:px-32 px-5  relative   bg-white">
          <div className="border-2 border-[#D1EFFA] shadow-black md:shadow-md relative md:-top-64   px-5 md:py-10 py-5 rounded-xl">
            <div className="flex md:gap-5 gap-2 pb-5 justify-center  items-center">
              <p className="border-t-4   md:w-[30%] w-[20%]"></p>
              <p className="md:text-base  font-bold text-gray-500 w-fit">
                AND THAT’S NOT ALL...
              </p>
              <p className="border-t-4  md:w-[30%] w-[20%]"></p>
            </div>
            <div className="md:grid grid-cols-3 flex flex-col gap-5 ">
              <div className="flex flex-col items-center text-center px-3  rounded-lg hover:border-gray-200 border border-white transition-all hover:shadow-lg shadow-black pb-2">
                
                <div className="my-4 flex items-center justify-center bg-gradient-to-br from-yellow-400 via-yellow-500 to-yellow-600 p-4 rounded-full shadow shadow-black">
                  <FaBell className="text-white text-3xl" />
                </div>
                <h1 className="md:text-xl text-lg font-semibold">
                  Auto Notifications
                </h1>
                <p className="text-gray-500 md:text-lg text-sm">
                  Automatic notifications to clients when a new matching
                  property is added
                </p>
              </div>
              <div className="flex flex-col items-center text-center px-3  rounded-lg hover:border-gray-200 border border-white transition-all hover:shadow-lg shadow-black pb-2">
                {/* <img src="/images/share.png" alt="" className=" h-14 my-5" /> */}
                <div className="my-4 flex items-center justify-center bg-gradient-to-b from-sky-400 to-blue-700 p-4 rounded-full shadow shadow-black">
                  <FaShare className="text-white text-3xl" />
                </div>

                <h1 className="md:text-xl text-lg font-semibold">
                  Share Properties
                </h1>
                <p className="text-gray-500 md:text-lg text-sm">
                  Share Property details and images to clients directly from
                  RealtorApp.io
                </p>
              </div>
              <div className="flex flex-col items-center text-center px-3  rounded-lg hover:border-gray-200 border border-white transition-all hover:shadow-lg shadow-black pb-2">
                {/* <img src="/images/6.png" alt="" /> */}
                <div className="my-4 flex items-center justify-center bg-gradient-to-b from-pink-400 to-orange-500 p-4 rounded-full shadow shadow-black">
                  <MdManageAccounts className="text-white text-3xl" />
                </div>

                <h1 className="md:text-xl text-lg font-semibold">
                  Incentive Management
                </h1>
                <p className="text-gray-500 md:text-lg text-sm">
                  Lets executives to track their incentives and boosts their
                  morale.
                </p>
              </div>
              
              <div className="flex flex-col items-center text-center px-3  rounded-lg hover:border-gray-200 border border-white transition-all hover:shadow-lg shadow-black pb-2">
                
                <div className="bg-gradient-to-br my-4 w-14 h-14 flex justify-center items-center from-purple-500 to-indigo-500 p-2 rounded-full shadow shadow-black">
                  <MdOutlineContactPhone className="mt-1 h-8 w-8 text-white" />
                </div>
               
                <h1 className="md:text-xl text-lg font-semibold">
                  Viewed Contacts
                </h1>
                <p className="text-gray-500 md:text-lg text-sm">
                  Keep track of subusers who viewed contact of a client
                </p>
              </div>
              <div className="flex flex-col items-center text-center px-3  rounded-lg hover:border-gray-200 border border-white transition-all hover:shadow-lg shadow-black pb-2">
                <div className="my-4 flex items-center justify-center bg-gradient-to-b from-green-400 to-teal-500 p-4 rounded-full shadow shadow-black">
                  <FaChartLine className="text-white text-3xl" />
                </div>
                <h1 className="md:text-xl text-lg font-semibold">
                  Simply Scalable
                </h1>
                <p className="text-gray-500 md:text-lg text-sm">
                  Increase or decrease number of subusers any time
                </p>
              </div>
              <div className="flex flex-col items-center text-center px-3  rounded-lg hover:border-gray-200 border border-white transition-all hover:shadow-lg shadow-black pb-2">
                
                <div className="my-4 flex items-center justify-center bg-gradient-to-b from-purple-500 to-pink-400 p-4 rounded-full shadow shadow-black">
                  <FaUserClock className="text-white text-3xl" />
                </div>

                <h1 className="md:text-xl text-lg font-semibold">Task Reminders</h1>
                <p className="text-gray-500 md:text-lg text-sm">
                  Never Forgot to make follow up call
                </p>
              </div>
             
              <div className="flex flex-col items-center text-center px-3  rounded-lg hover:border-gray-200 border border-white transition-all hover:shadow-lg shadow-black pb-2">
                <div className="my-4 bg-gradient-to-br w-14 h-14 flex justify-center items-center from-teal-300 to-indigo-300 p-2 rounded-full shadow shadow-black">
                  <FaFilter className="h-8 w-8 text-white" />
                </div>

                <h1 className="md:text-xl text-lg font-semibold">
                  Filter Matching
                </h1>
                <p className="text-gray-500 md:text-lg text-sm">
                  Get Properties / Inquiries from existing data instantly
                </p>
              </div>
              <div className="flex flex-col items-center text-center px-3  rounded-lg hover:border-gray-200 border border-white transition-all hover:shadow-lg shadow-black pb-2">
                <div className="bg-gradient-to-br my-4 w-14 h-14 flex justify-center items-center from-red-400 to-yellow-500 p-2 rounded-full shadow shadow-black">
                  <IoLogOutSharp className="h-8 w-8 text-white" />
                </div>

                <h1 className="md:text-xl text-lg font-semibold">
                  Logout All Devices
                </h1>
                <p className="text-gray-500 md:text-lg text-sm">
                  Doubt if password of any user is compromised, Simply change password and signout all devices.
                </p>
              </div>
              <div className="flex flex-col items-center text-center px-3  rounded-lg hover:border-gray-200 border border-white transition-all hover:shadow-lg shadow-black pb-2">
                <div className="my-4 flex items-center justify-center bg-gradient-to-b from-blue-400 to-indigo-600 p-4 rounded-full shadow shadow-black">
                  <MdDevices className="text-white text-3xl" />
                </div>

                <h1 className="md:text-xl text-lg font-semibold">
                  Web App + Mobile App
                </h1>
                <p className="text-gray-500 md:text-lg text-sm">
                  Seamless data at your finger tips
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className=" px-6 md:px-32 md:pb-20 pb-5 pt-5 md:pt-0 bg-white ">
          <div className="relative md:-top-52 md:-mb-60">
            <div className="bg-white grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5  ">
              {/* Card 1 */}
              <div className="w-full md:h-56 border bg-gradient-to-tl from-blue-200 via-blue-300 to-blue-400  rounded-xl px-8 py-4 flex flex-col items-center text-center transform transition-all duration-300 hover:scale-105 hover:shadow-2xl">
                <FaMoneyBillAlt className="text-blue-700 text-6xl  p-3 rounded-full bg-white shadow-lg" />
                <h2 className="text-2xl font-semibold text-gray-800 mt-6  mb-2">
                  Earn from Home Loan Referrals
                </h2>
                <p className="text-gray-700 text-sm md:text-base md:mb-6 px-6">
                  Keep track of home loan files and create an additional income
                  stream effortlessly.
                </p>
              </div>

              {/* Card 2 */}
              <div className="w-full md:h-56 border bg-gradient-to-tr  from-red-200 to-yellow-400  rounded-xl px-8 py-4 flex flex-col items-center text-center transform transition-all duration-300 hover:scale-105 hover:shadow-2xl">
                <FaLock className=" text-yellow-700 text-6xl p-3  rounded-full bg-white shadow-lg" />
                <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">
                  Data Encryption
                </h2>
                <p className="text-gray-700 text-sm md:text-base md:mb-6 px-6">
                  Ensure your data remains secure with top-notch encryption.
                </p>
              </div>

              {/* Card 3 */}
              <div className="w-full md:h-56 border bg-gradient-to-bl from-green-200 via-green-300 to-green-400  rounded-xl px-8 py-4 flex flex-col items-center text-center transform transition-all duration-300 hover:scale-105 hover:shadow-2xl">
                <FaExchangeAlt className="text-green-700 text-6xl  p-3 rounded-full bg-white shadow-lg" />
                <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-2">
                  Smart Data Migration
                </h2>
                <p className="text-gray-700 text-sm md:text-base md:mb-6 px-6">
                  Easily migrate data with the help of AI.
                </p>
              </div>

              {/* Card 4 */}
              <div className="w-full md:h-56 border bg-gradient-to-br  from-purple-200 via-purple-300 to-purple-400  rounded-xl px-8 py-4 flex flex-col items-center text-center transform transition-all duration-300 hover:scale-105 hover:shadow-2xl">
                <FaUserCheck className="text-purple-700 text-6xl mb-6 p-3 rounded-full bg-white shadow-lg" />
                <h2 className="text-2xl  font-semibold text-gray-800 mb-2">
                  Simple to Use
                </h2>
                <p className="text-gray-700 text-sm md:text-base md:mb-6 px-6">
                  No training required. Still we'll provide video tutorials on social
                  media.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-5 md:px-32 py-2 md:py-10 md:pt-0  bg-white">
 
    <div className="bg-gray-50 border-2 border-[#D1EFFA] rounded-lg overflow-hidden">
      {/* Header */}
      <div className="bg-[#1E9DE4] md:p-6 p-3 border-b-2  text-center text-white">
        <h1 className="md:text-3xl text-xl font-bold">How to increase sale ROI</h1>
      </div>
      
      {/* Links */}
      <div className="md:p-8 p-4 text-gray-800">
        <div className="space-y-4">
          <Link
            to="/qna#question-1"
            className="block md:p-4 p-2 rounded-lg bg-blue-50 hover:bg-blue-100 border border-blue-200 shadow-sm hover:shadow-lg transition-all duration-300"
          >
            <h2 className="md:text-lg text-base  font-semibold text-blue-700">
              1. How to Build High Performance Sales Team?
            </h2>
          </Link>
          <Link
            to="/qna#question-2"
            className="block md:p-4 p-2 rounded-lg bg-green-50 hover:bg-green-100 border border-green-200 shadow-sm hover:shadow-lg transition-all duration-300"
          >
            <h2 className="md:text-lg text-base  font-semibold text-green-700">
              2. Investing in Property Portals, Newspapers, Social Media?
            </h2>
          </Link>
          <Link
            to="/qna#question-3"
            className="block md:p-4 p-2 rounded-lg bg-yellow-50 hover:bg-yellow-100 border border-yellow-200 shadow-sm hover:shadow-lg transition-all duration-300"
          >
            <h2 className="md:text-lg text-base  font-semibold text-yellow-700">
              3. Analyzing Sources for Better Investment Decisions
            </h2>
          </Link>
        </div>
      </div>
    </div>
  
</div>


        <div className="md:px-32 px-5 md:pb-20 pb-5 pt-5 md:pt-0 bg-white ">
          <div className="">
            <div
              className="border rounded-xl flex items-center pt-5 bg-[#e3f9fa]  justify-between px-5"
              style={{
                backgroundImage: "url('/images/netBg.png')",
                // backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="hidden w-[40%] md:flex flex-col gap-5 ">
                <h1 className="text-center text-3xl font-bold">
                  Get started with RealtorApp.io
                </h1>
                <div className="flex  justify-center w-full ">
                  {/* <div className="flex">
                  <span className="flex justify-center items-center bg-white border rounded-s-xl border-e-4 px-2 font-semibold">
                    +91
                  </span>
                  <input
                    type="number"
                    name="mobile"
                    id="mobile"
                    placeholder="Enter your phone number "
                    className="w-60 border p-2 rounded-e-xl bg-gradient-to-r  from-[#ffffff] to-[#00fff7]"
                  />
                </div> */}
                  <Link
                    to="/register"
                    className="bg-gradient-to-b from-teal-300 to-blue-500  p-2 px-5 border rounded-xl text-white font-semibold"
                  >
                    Sign Up
                  </Link>
                </div>

                <div className="flex justify-between text-center items-center ">
                  <p className="w-[40%] text-sm text-center border-e-4">
                    24x7 Access Anywhere, Anytime
                  </p>
                  <p className="w-[10%] text-sm text-center ">Data Safety</p>
                  <p className="w-[40%] text-sm text-center border-s-4">
                    Get 3x Work done From each team member
                  </p>
                </div>
              </div>
              <div>
                <img
                  src="/images/mobileapp.png"
                  alt=""
                  className="md:h-[400px] h-[200px]"
                />
              </div>
              <div className="flex flex-col gap -5 items-center">
                {/* <img src="/images/Qr.png" alt="" className="h-32 w-32"/> */}
                <p className="md:text-xl text-base text-center">
                  Mobile App Coming Soon On
                </p>
                <div className="px-5 flex flex-col gap-2">
                  <div className="bg-black rounded-xl p-2 border shadow-sm shadow-black text-white flex items-center justify-evenly">
                    <img
                      src="/images/android.png"
                      alt=""
                      className="md:w-10 w-10"
                    />
                    <div>
                      <p className="text-[10px]">Android app On</p>
                      <p className="md:text-lg md:font-semibold ">
                        Google Play
                      </p>
                    </div>
                  </div>
                  <div className="bg-black rounded-xl p-2 border shadow-sm shadow-black text-white flex items-center justify-evenly">
                    <img src="/images/ios1.png" alt="" className="w-10" />
                    <div>
                      <p className="text-[10px]">Download on the</p>
                      <p className="md:text-lg md:font-semibold">Apple store</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" p-2 rounded-xl flex  md:mt-10 mt-5 justify-center items-center gap-2 bg-[#37DAD5]">
              <p className="text-white md:text-lg">
                Try RealtorApp.io Desktop on a sample DEMO account.{" "}
                <span className="text-black font-bold">
                  No signup required.
                </span>
              </p>
              <button
                className="bg-gradient-to-b  from-teal-300 to-blue-500 text-white px-5 py-2 font-bold  rounded-xl"
                onClick={handleSubmit}
              >
                View Demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
