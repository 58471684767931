import {BrowserRouter, Routes, Route} from 'react-router-dom'
import "react-virtualized/styles.css";
import "yet-another-react-lightbox/styles.css";
import { lazy, Suspense } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "./components/Header"
import Footer from "./components/Footer"
import Welcome from './pages/welcome/Welcome';
import { useSelector } from 'react-redux';
import Buy from './pages/offers/Buy';
import QnA from './pages/welcome/QnA';
import PaymentStatus from './pages/offers/PaymentStatus';

const Login = lazy(() => import('./authentication/Login'));
const Register = lazy(() => import('./authentication/Register'));
const Profile = lazy(() => import('./pages/Profile'));
const PageNotFoun = lazy(() => import('./pages/PageNotFound'));
const SubUser = lazy(() => import('./pages/subuser/SubUser'));
// const ConfRegister = lazy(() => import('./pages/ConfRegister'));
const AddProperty = lazy(() => import('./pages/property/AddProperty'));
const BulkProperty = lazy(() => import('./pages/property/BulkPropertyAdd'));
const Protected = lazy(()=> import('./protectedRoutes/Protected'));
const AreaList = lazy(()=> import('./pages/area/AreaList'));
const SocietyList = lazy(()=> import('./pages/society/SocietyList'));
const Contact = lazy(()=> import('./pages/contact/Contact'));
const AddInquiry = lazy(()=> import('./pages/inquiry/AddInquiry'));
const BulkInquiry = lazy(()=> import('./pages/inquiry/BulkInquiry'));
const Deal = lazy(()=>import('./pages/deal/Deal'));
const AddTask = lazy(()=>import('./pages/tasks/AddTask'));
const Sources = lazy(()=>import('./pages/Sources'));
const ContGroupList = lazy(()=>import('./pages/contactgroup/ContGroupList'));
const Support = lazy(()=>import('./pages/Support'));
const Verification = lazy(()=>import('./authentication/Verification'));
const TaskList = lazy(()=> import('./pages/tasks/TaskList'));
const DealList = lazy(()=> import('./pages/deal/DealList'));
const UserProfile = lazy(()=> import('./pages/subuser/UserProfile'));
const Searchfilters = lazy(()=> import('./pages/property/SearchProperties'));
const SearchInquiryResults = lazy(()=> import('./pages/inquiry/SearchInquiryresults'));
const Projects = lazy(()=> import('./pages/projects/ProjectList'));
const AddProjects = lazy(()=> import('./pages/projects/AddProjects'));
const AddLoan = lazy(()=> import('./pages/loan/AddLoan'));
const LoanList = lazy(()=> import('./pages/loan/LoanList'));
const ProtectedOwnerCo = lazy(()=> import('./protectedRoutes/OwnerCoOnly'));
const AddContact = lazy(()=> import('./pages/contact/AddContact'));
// const Inquiry = lazy(()=> import('./pages/99acres/Inquiry'));
const Detail  = lazy(()=>import('./pages/payment/Detail'));
const PropertyFilter = lazy(()=>import('./pages/property/Filter'));
const InquiryFilter = lazy(()=>import('./pages/inquiry/Filter'));
const ChangePass = lazy(()=>import("./authentication/ChangePass"))
const ForgotPass = lazy(()=>import("./authentication/ForgotPass"))
const ForgotPassConfirm = lazy(()=>import("./authentication/ForgotPassConfirm"))
const Home = lazy(()=>import("./pages/home/Home"))
const Dashboard = lazy(()=>import("./pages/dashboard/Dashboard"))
const ViewInquiry =lazy(()=>import('./pages/viewcontact/ViewInquiry'));
const ViewProperty =lazy(()=>import('./pages/viewcontact/ViewProperty'));
const UserDevice =lazy(()=>import('./pages/userDevice/UserDevice'));
const Policy = lazy(()=>import('./pages/policy/Policy'))
const Offer = lazy(()=>import('./pages/offers/Offer'))
const CommissionList =  lazy(()=>import('./pages/commission/CommissionList'))
const AddAmount =  lazy(()=>import('./pages/commission/AddAmount'))
// const PropertyDetail = lazy(()=>import('./pages/property/Detail'))





function App() {
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  return (
    // <div className=" text-[12px] md:text-[16px] bg-gradient-to-b from-[#1E9DE4] to-[#C0E1F4] ">
    <div className=" text-[12px] md:text-[16px]  bg-gray-100 ">
      <BrowserRouter>
      <ToastContainer
position="top-center"
autoClose={3000}
hideProgressBar={true}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
        <Header/>
        <Suspense fallback={<div className="flex h-[50vw] justify-center items-center">
<div className="animate-spin rounded-full h-20 w-20 border-t-4 border-blue-500 border-solid"></div>
</div>}>

        <Routes>

          {/* Public Routes  */}
          
          <Route exact path='/login' element={<Login/>} />
          <Route exact path='/register' element={<Register/>} />
          {/* <Route exact path='/welcome' element={<Welcome/>} /> */}
          {/* <Route exact path='/team' element={<Team/>} /> */}
          <Route exact path='/forgotpass' element={<ForgotPass/>} />
          {/* <Route exact path='/signupConfirmation' element={<ConfRegister/>} /> */}
          <Route exact path='/support' element={<Support/>} />
          <Route exact path='/activation/:uid/:token' element={<Verification/>} />
          <Route exact path='/password-reset/:uid/:token' element={<ForgotPassConfirm/>} />
          <Route exact path='/policy/:type' element={<Policy/>} />
          <Route exact path='/offers' element={<Offer/>} />
          <Route exact path='/qna' element={<QnA/>} />

          {/* Owner only routes  */}
          {/* <Route element={<Protected/>}><Route path="/"  element={<Home/>}/></Route> */}
          {isAuthenticated ? (
            <Route element={<Protected />}><Route path="/" element={<Home />} /></Route>
          ) : (
            <Route exact path="/" element={<Welcome />} />
          )}

          <Route element={<Protected/>}><Route path="/profile"  element={<Profile/>}/></Route>
          <Route element={<Protected/>}><Route path="/subuser"  element={<SubUser/>}/></Route>
          <Route element={<Protected/>}><Route path="/addProperty"  element={<AddProperty/>}/></Route>
          {/* <Route element={<Protected/>}><Route path="/property/:id"  element={<PropertyDetail />}/></Route> */}
          {/* <Route element={<Protected/>}><Route path="/searchProperty"  element={<SearchProperty/>}/></Route> */}
          <Route element={<Protected/>}><Route path="/propertyFilter"  element={<PropertyFilter/>}/></Route>
          <Route element={<Protected/>}><Route path="/searchProperty"  element={<Searchfilters/>}/></Route>
          <Route element={<Protected/>}><Route path="/bulkproperty"  element={<BulkProperty/>}/></Route>
          <Route element={<Protected/>}><Route path="/arealist"  element={<AreaList/>}/></Route>
          <Route element={<Protected/>}><Route path="/societyList"  element={<SocietyList/>}/></Route>
          <Route element={<ProtectedOwnerCo/>}><Route path="/contact"  element={<Contact/>}/></Route>
          <Route element={<Protected/>}><Route path="/addInquiry"  element={<AddInquiry/>}/></Route>
          {/* <Route element={<Protected/>}><Route path="/searchInquirys"  element={<SearchInquiry/>}/></Route> */}
          <Route element={<Protected/>}><Route path="/bulkInquiry"  element={<BulkInquiry/>}/></Route>
          <Route element={<Protected/>}><Route path="/searchInquiry"  element={<SearchInquiryResults/>}/></Route>
          <Route element={<Protected/>}><Route path="/inquiryFilter"  element={<InquiryFilter/>}/></Route>
          {/* <Route element={<Protected/>}><Route path="/datamigration"  element={<DataMigration/>}/></Route> */}
          <Route element={<Protected/>}><Route path="/deal"  element={<Deal/>}/></Route>
          <Route element={<Protected/>}><Route path="/addtask"  element={<AddTask/>}/></Route>
          <Route element={<Protected/>}><Route path="/addtask/inquiry/:id"  element={<AddTask/>}/></Route>
          <Route element={<Protected/>}><Route path="/addtask/property/:id"  element={<AddTask/>}/></Route>
          <Route element={<Protected/>}><Route path="/sources"  element={<Sources/>}/></Route>
          <Route element={<Protected/>}><Route path="/addcontact"  element={<AddContact/>}/></Route>
          <Route element={<Protected/>}><Route path="/contactgroup"  element={<ContGroupList/>}/></Route>
          <Route element={<Protected/>}><Route path="/changepassword"  element={<ChangePass/>}/></Route>
          <Route element={<Protected/>}><Route path="/tasks"  element={<TaskList/>}/></Route>
          <Route element={<Protected/>}><Route path="/deals"  element={<DealList/>}/></Route>
          <Route element={<Protected/>}><Route path="/subuserprofile"  element={<UserProfile/>}/></Route>
          {/* <Route element={<Protected/>}><Route path="/task"  element={<Task/>}/></Route> */}
          {/* <Route element={<Protected/>}><Route path="/addtask"  element={<AddTask/>}/></Route> */}
          <Route element={<Protected/>}><Route path="/projects"  element={<Projects/>}/></Route>
          <Route element={<Protected/>}><Route path="/addproject"  element={<AddProjects/>}/></Route>
          <Route element={<Protected/>}><Route path="/addunit/:area/:society"  element={<AddProperty/>}/></Route> 
          <Route element={<Protected/>}><Route path="/addloan"  element={<AddLoan/>}/></Route> 
          <Route element={<Protected/>}><Route path="/loans"  element={<LoanList/>}/></Route> 
          {/* <Route element={<Protected/>}><Route path="/99acres"  element={<Inquiry/>}/></Route>  */}
          <Route element={<Protected/>}><Route path="/paymentdetail"  element={<Detail/>}/></Route> 
          <Route element={<Protected/>}><Route path="/commission"  element={<CommissionList/>}/></Route> 
          <Route element={<Protected/>}><Route path="/buy/:duration"  element={<Buy/>}/></Route> 
          <Route element={<Protected/>}><Route path="/paymentstatus"  element={<PaymentStatus/>}/></Route> 
          <Route element={<ProtectedOwnerCo/>}><Route path="/dashboard"  element={<Dashboard/>}/></Route> 
          <Route element={<ProtectedOwnerCo/>}><Route path="/viewInquiry"  element={<ViewInquiry/>}/></Route> 
          <Route element={<ProtectedOwnerCo/>}><Route path="/viewProperty"  element={<ViewProperty/>}/></Route> 
          <Route element={<ProtectedOwnerCo/>}><Route path="/userdevice"  element={<UserDevice/>}/></Route> 
          {/* <Route element={<ProtectedOwnerCo/>}><Route path="/commission"  element={<CommissionList/>}/></Route>  */}
          <Route element={<ProtectedOwnerCo/>}><Route path="/addcommission/:id"  element={<AddAmount/>}/></Route> 
          
          <Route path='*' element={<PageNotFoun/>}/>
        </Routes>
        </Suspense>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
