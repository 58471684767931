import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const MainSlider = ({ handleSubmit }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    appendDots: (dots) => (
      <div
        style={{
          padding: "10px",
          bottom: "-30px",
        }}
      >
        <ul
          style={{ margin: "0px", display: "flex", justifyContent: "center" }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "14px", // Slightly bigger size
          height: "14px",
          backgroundColor: "lightgray", // Default color for all dots
          borderRadius: "50%", // Circular shape
          margin: "0 5px", // Spacing between dots
          cursor: "pointer",
          transition: "transform 0.3s ease, background-color 0.3s ease",
        }}
        className={`dot-${i}`}
      />
    ),
  };

  return (
    <>
      <Slider {...settings}>
        <div>
          {/* First Slide - Already Completed */}
          <div className="flex w-full md:pl-20 px-5 md:gap-20 justify-center flex-col md:flex-row">
            <div className="md:w-[40%] md:mt-10 flex border items-center flex-col md:gap-10 gap-5 md:mb-20 mb-10 md:p-5 p-3 rounded-2xl bg-gradient-to-b from-teal-300 to-blue-500">
              <div>
                <h1 className="text-center md:text-3xl text-xl font-bold">
                  Close More Deals with easy to use RealtorApp.io
                </h1>
              </div>
              <div className="flex justify-center w-full px-8">
                <Link
                  to="/register"
                  className="bg-gradient-to-b from-teal-300 to-blue-500  p-2 px-5 border rounded-xl text-white font-semibold"
                >
                  Sign Up
                </Link>
              </div>
              <div className="border rounded-xl p-2 bg-gradient-to-r md:mx-4 from-[#fcffff] to-[#bdfffd]">
                <h2 className="md:text-lg text-sm text-center font-bold pb-2">
                  Growth Engine For Your Business
                </h2>
                <div className="flex justify-between items-center">
                  <p className="w-[40%] text-sm text-center border-e-4">
                    24x7 Access Anywhere, Anytime
                  </p>
                  <p className="w-[10%] text-sm text-center">Data Safety</p>
                  <p className="w-[40%] text-sm text-center border-s-4">
                    Get 3x Work done From each team member
                  </p>
                </div>
              </div>
              <div className="p-2 rounded-xl flex justify-center items-center bg-gradient-to-r md:mx-4 from-violet-500 to-orange-500">
                <p className="text-white text-sm">
                  Try RealtorApp.io Desktop on a sample DEMO account.{" "}
                  <span className="text-black font-bold">
                    No signup required.
                  </span>
                </p>
                <button
                  className="bg-gradient-to-b h-fit from-teal-300 to-blue-500 py-3 md:py-2  w-1/2 font-semibold text-white rounded-xl"
                  onClick={handleSubmit}
                >
                  View Demo
                </button>
              </div>
            </div>
            <div
              className="hidden md:w-[60%] md:flex  justify-center items-end"
              style={{
                backgroundImage: "url('/images/circle.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div>
                <img src="/images/stockImg.png" alt="" className="w-full " />
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* second Slide */}
          <div className="flex w-full px-5 md:gap-20 justify-center flex-col md:flex-row">
            <div
              className=" hidden md:w-[60%] md:flex justify-center items-end"
              style={{
                backgroundImage: "url('/images/circle.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <DotLottieReact
                // src="https://lottie.host/8ecd0d9f-621d-4e0d-aebd-5ba283923a16/Fh3Q3Xa0qm.lottie"
                src="https://lottie.host/cb88ae06-a260-4879-8619-4027c35bfc37/xfEBK5Fsx4.lottie"
                // src="https://lottie.host/f89a1f3f-c9c9-4a32-b462-77a0a26e2013/ZrKBkfvIzI.lottie"
                loop
                autoplay
                style={{ height: "100%", width: "100%" }} // Make sure the Lottie animation takes up full space
              />
              {/* <img src="/images/99acres-feature.png" alt="99acres Feature" className="w-full" /> */}
            </div>

            <div className="md:w-[50%] md:mr-10 flex justify-center items-center md:h-[80vh]  p-5 ">
              <div className="relative bg-gray-100 shadow-xl rounded-3xl  p-5 md:p-12 text-center">
                <h1 className="text-2xl md:text-3xl font-extrabold text-gray-800 mb-6">
                  Fetch Inquiries Instantly from Property Portals
                </h1>
                <p className="text-lg md:text-2xl text-gray-600 font-medium mb-6 leading-relaxed">
                  Also automatically assign inquiries to the right team members
                  in seconds.
                </p>

                <Link
                  to={"/login"}
                  className="md:mt-6 px-8 py-3 text-white font-bold rounded-full bg-gradient-to-r from-teal-400 to-blue-500 hover:shadow-lg transform hover:scale-105 transition-all"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          {/* third Slide */}
          <div className="flex w-full md:pl-20 px-5 md:gap-20 justify-center flex-col md:flex-row">
            <div className="md:w-[50%] md:mr-10 flex justify-center items-center md:h-[80vh]  p-5 ">
              <div className="relative bg-gray-100 shadow-xl rounded-3xl  p-5 md:p-12 text-center">
                <h1 className="text-2xl md:text-3xl font-extrabold text-gray-800 mb-16">
                  Simply send Property message to owner to add property.
                </h1>

                <Link
                  to={"/login"}
                  className=" px-8 py-3 text-white font-bold rounded-full bg-gradient-to-r from-teal-400 to-blue-500 hover:shadow-lg transform hover:scale-105 transition-all"
                >
                  Get Started
                </Link>
              </div>
            </div>
            <div
              className="hidden md:w-[60%] md:flex  justify-center items-end"
              style={{
                backgroundImage: "url('/images/circle.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <DotLottieReact
                src="https://lottie.host/140deb63-de1d-4768-b77f-cc57952d72f4/UC2NYN5ZUA.lottie"
                loop
                autoplay
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
        </div>

        {/* <div className="relative w-full ">
          <div className="absolute inset-0 bg-gradient-to-r from-[#F9D5D3] to-[#FFB6A7] z-0"></div>

          <div className="relative z-10 flex flex-col md:flex-row justify-center items-center w-full h-full text-white p-10">
            <div className="flex flex-col justify-center items-center text-center md:px-32 md:text-left mb-8 md:mb-0 w-[70%]">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight mb-8 text-shadow-xl text-[#4A2A2A]">
              Just forward <span className="text-red-50">WhatsApp  message</span> to add property into  <span className="text-red-50">CRM</span>
              </h1>

              <Link
                to={"/login"}
                className="px-10 py-4 text-lg font-bold text-white bg-gradient-to-r from-[#FDCB82] to-[#FF7F50] rounded-full transform transition-all hover:scale-110 hover:shadow-xl"
              >
                Get Started
              </Link>
            </div>

            <div
              className="flex justify-center w-[30%]"
              style={{ height: "80vh", width: "auto" }}
            >
              <DotLottieReact
                src="https://lottie.host/140deb63-de1d-4768-b77f-cc57952d72f4/UC2NYN5ZUA.lottie"
                loop
                autoplay
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
        </div> */}

        <div>
          {/* Fourth Slide */}
          <div className="flex w-full px-5 md:gap-20 justify-center flex-col md:flex-row">
            <div
              className=" hidden md:w-[60%] md:flex justify-center items-end"
              style={{
                backgroundImage: "url('/images/circle.png')",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <DotLottieReact
                src="https://lottie.host/4e72c5e1-25b5-435c-af98-81c1dcfc0a7d/6keTJQMRXZ.lottie"
                //  src="https://lottie.host/1f340a91-8f29-4463-921f-7108a27c1da0/WDM9VecuUI.lottie"
                loop
                autoplay
                style={{ height: "100%", width: "100%" }}
              />
            </div>

            {/* Right Section - Message */}
            <div className="md:w-[50%] flex justify-center items-center md:h-[80vh] p-5">
              <div className="relative bg-white shadow-2xl rounded-3xl p-5 md:p-12 text-center">
                <h1 className="text-2xl md:text-4xl font-extrabold text-gray-800 mb-6 leading-tight">
                  Experience the Power of{" "}
                  <span className="text-blue-500">RealtorApp.io</span>
                </h1>
                <p className="text-lg md:text-2xl text-gray-600 font-medium mb-6 leading-relaxed">
                  A modern, colorful, and intuitive interface that transforms
                  your business workflow.
                </p>

                <Link
                  to="/explore"
                  className="px-8 py-3 text-white font-bold rounded-full bg-gradient-to-r from-indigo-500 to-purple-500 hover:shadow-xl transform hover:scale-105 transition-all"
                >
                  Explore the Features
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
};

export default MainSlider;
