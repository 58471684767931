import React, { useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import useFetchWithoutToken from "../../hook/useFetchWithoutToken";
import {  FaMinus, FaPlus } from "react-icons/fa";
import { BASE_URL } from "../../util/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Buy = () => {
  const { duration } = useParams();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const token = useSelector((state) => state.auth.accessToken);
  const { data: plan, loading: planLoading } = useFetchWithoutToken(
    "/properties/plans/"
  );
  const { data: planPrice, loading: priceLoading } = useFetchWithoutToken(
    "/properties/plan-prices/"
  );
  const today = new Date();
  const formattedDate = today.toLocaleDateString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  const [subUserCount, setSubUserCount] = useState(0);

  const filteredPrice =
    planPrice?.results?.filter((price) => price.duration === duration) || [];
  const ownerAccountCost =
    filteredPrice[0]?.duration === "MONTHLY"
      ? filteredPrice[0]?.owner_price_per_month
      : filteredPrice[0]?.duration === "HALF_YEARLY"
      ?  filteredPrice[0]?.owner_price_per_month * 6
      : filteredPrice[0]?.duration === "QUARTERLY" ? filteredPrice[0]?.owner_price_per_month * 3 : filteredPrice[0]?.owner_price_per_month * 12;

  const subUserCost =
    filteredPrice[0]?.duration === "MONTHLY"
      ? filteredPrice[0]?.subuser_price_per_month
      : filteredPrice[0]?.duration === "HALF_YEARLY"
      ? filteredPrice[0]?.subuser_price_per_month * 6
      : filteredPrice[0]?.duration === "QUARTERLY" ? filteredPrice[0]?.subuser_price_per_month * 3 : filteredPrice[0]?.subuser_price_per_month * 12;

  const totalAmount = ownerAccountCost + subUserCount * subUserCost;
  // const totalAmountWithGst = totalAmount*0.18 + totalAmount

  const handleIncrease = () => setSubUserCount((prev) => prev + 1);
  const handleDecrease = () =>
    subUserCount > 0 && setSubUserCount((prev) => prev - 1);
  
  

  const planName = 
  (filteredPrice?.length > 0 ||  planLoading) &&
  plan?.results?.find((item) => parseInt(item.id) === parseInt(filteredPrice[0]?.plan));


  const handleProceed = async () =>{
    setLoading(true)
     try {
       const formDataToSend = new FormData()


       formDataToSend.append("amount",totalAmount)
       formDataToSend.append("plan_id",planName.id)
       formDataToSend.append("number_of_subusers",subUserCount)
       formDataToSend.append("duration",duration)

      const response = await fetch(`${BASE_URL}/paisaa/initiate_payment/`,{
        method:"POST",
        headers:{
          // "Content-type" : "application/json",
          "Authorization" : `JWT ${token}`
        },
        body: formDataToSend,
      });
      const data = await response.json()

      if(response.ok){
        toast.success("Payment Initiated !")
        // console.log("data.redirect_url",data.redirect_url);
        window.location.href = data.redirect_url;
        navigate(data.redirect_url)
      }else{
        console.log("resoponse data", data);
        toast.error("Error :",data)
      }
      
     } catch (error) {
      console.error("error",error);
      toast.error("Error :",error)
      
     }finally{
      setLoading(false)
     }
  }

  return (
    <div className=" bg-gradient-to-r from-gray-50 to-gray-100 flex justify-center  items-center md:py-10 md:pt-[100px] pt-[80px]">
      
        <div className="md:w-[70vw]">
       
          <h1 className="text-2xl font-bold text-gray-900 text-center mb-5">
            Subscription Details
          </h1>
          {priceLoading || filteredPrice?.length === 0 ? (
        <div className="bg-white   p-3 rounded-xl shadow-md h-screen md:w-[70vw]  w-[90vw]"><div className="bg-gradient-to-r from-blue-100 to-purple-100 rounded-xl p-6 h-24  w-full mb-3"></div></div>
      ) : (
          <div className="bg-white  p-3 rounded-xl shadow-md m-3 md:m-0">
            <div className="bg-gradient-to-r from-blue-100 to-purple-100 rounded-xl md:p-6 p-3  mb-3">
              <p
                className="flex justify-between items-center md:text-2xl text-xl font-semibold cursor-pointer"
                //   onClick={() => setShowFeatures(!showFeatures)}
              >
                {/* Basic Plan */}
                {planName?.plan_name || ""}
              </p>
              <p className="font-semibold text-gray-500">
                {duration === "MONTHLY"
                  ? "1 Month"
                  : duration === "HALF_YEARLY"
                  ? "6 Months"
                  : duration === "QUARTERLY" ? "3 Months" : "1 Year"}
              </p>
              <p  className="font-semibold text-gray-500">Start Date : {formattedDate}</p>
            </div>

            {/* Pricing Section */}
            <div className="flex flex-col md:gap-6 gap-4  md:p-6 p-2">
              {/* Owner Account */}
              <div className=" flex  justify-between items-center">
                <div className="">
                  <h3 className="md:text-xl text-base font-semibold text-gray-800 md:mb-3 ">
                    Owner Account
                  </h3>

                  {filteredPrice[0]?.duration !== "MONTHLY" && (
                    <p className="text-md text-gray-600">
                      Cost per month :{" "}
                      <span className="font-semibold">
                        Rs. {filteredPrice[0]?.owner_price_per_month}
                      </span>
                    </p>
                  )}
                </div>{" "}
                <p className="md:text-xl text-base font-bold text-gray-900 ">
                  Rs. {ownerAccountCost}
                </p>
              </div>

              {/* Subuser Accounts */}
              <div className=" flex items-center justify-between border-t-2 pt-3">
                <div>
                  <h3 className="md:text-xl text-base font-semibold text-gray-800 md:mb-4 mb-2">
                    Subuser Accounts
                  </h3>
                  <div className="flex items-center gap-4">
                    <button
                      onClick={handleDecrease}
                      className={`p-2 rounded-full bg-gray-300 text-gray-500 ${
                        subUserCount > 0
                          ? ""
                          : " cursor-not-allowed"
                      }`}
                      disabled={subUserCount === 0}
                    >
                      <FaMinus />
                    </button>
                    <p className="text-xl font-semibold">{subUserCount}</p>
                    <button
                      onClick={handleIncrease}
                      className="p-2 rounded-full bg-gray-300 text-gray-500"
                    >
                      <FaPlus />
                    </button>
                  </div>
                  <p className="mt-3 text-md text-gray-600">
                    Cost per Subuser :{" "}
                    <span className="font-semibold">Rs. {subUserCost}</span>
                  </p>
                </div>
                <p className="md:text-xl text-base font-bold text-gray-900 mb-2">
                  Rs. {subUserCount * subUserCost}
                </p>
              </div>

              <div className="border-t-2 pt-3 flex items-center justify-between">
                <p className="md:text-xl text-sm text-gray-600 font-semibold">
                  Subtotal (1 Owner +{" "}
                   {subUserCount} Subusers) :{" "}
                </p>
                <p className="md:text-xl text-base font-bold text-gray-600">
                  Rs. {totalAmount}
                </p>
              </div>
              {/* <div className=" flex items-center justify-between">
                <p className="md:text-xl text-sm font-semibold">
                  Totat (18% Tax) :{" "}
                </p>
                <p className="md:text-xl text-base font-bold text-gray-900">
                  Rs. {totalAmountWithGst}
                </p>
              </div> */}
            </div>
            <div className="flex justify-between w-full items-center md:p-4 mt-2 mb-4">
            <p className="w-1/2 text-gray-500 font-semibold">
              You can also change number of subusers during the Plan
            </p>

            {/* Proceed Button */}
            <div className="">
              {/* <button
                className="md:px-8 md:py-3 px-4  py-2 shadow-black bg-gradient-to-r from-green-400 to-green-500 hover:from-green-500 hover:to-green-600 text-white md:text-lg font-bold rounded-lg shadow transition-transform transform text-sm"
                onClick={handleProceed}
              >
                Proceed to Buy
              </button> */}
              <button
                onClick={handleProceed}
                className="w-[150px] flex justify-center items-center shadow-sm shadow-black text-white bg-gradient-to-b from-teal-300 to-blue-500   focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              >
                {loading ? (
                  <div className="text-center  border-gray-300 h-6 w-6 animate-spin rounded-full border-2 border-t-blue-600" />
                ) : (
                  "Proceed to Buy"
                )}
              </button>
            </div>
          </div>
          </div> )}
        
          
        </div>
     
    </div>
  );
};

export default Buy;
