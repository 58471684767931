import { useEffect, useState } from "react"
import { getRequest } from "../util/Api"
// import { useSelector } from "react-redux"

const useFetch = (url)=>{
    const [data, setData] = useState("")
    const [loading, setLoading] = useState("")
    const [error, setError] = useState("")
    // const token = useSelector(state=>state.auth.accessToken)

    useEffect(()=>{
        setLoading(true)
        // console.log("Fetching data from:", url);
        getRequest(url)
        .then((data)=>{
            setData(data)
            setLoading(false)
        })
        .catch((err) => {
            setLoading(false);
            setError("Something went wrong!");
         });

    },[url])
    return {data, loading, error}

}

export default useFetch;



