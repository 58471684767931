import React, { useCallback, useEffect, useState } from "react";
import { FaBars, FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { IoCall } from "react-icons/io5";
import { jwtDecode } from "jwt-decode";
import { Link, useLocation } from "react-router-dom";
import {
  refreshAuthToken,
  logout as authLogout,
} from "../redux/Slices/authSlice";
import { BASE_URL } from "../util/Api";
import useFetch from "../hook/useFetch";
import GeneralAlert from "../pages/GeneralAlert";
import { toast } from "react-toastify";
import { namesLogout } from "../redux/Slices/namesSlice";
import Sidebar from "./Sidebar";
import { subUserLogout } from "../redux/Slices/subUserSlice";
import { clearProperty } from "../redux/Slices/propertySlice";

const Header = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.accessToken);
  const refreshToken = useSelector((state) => state.auth.refreshToken);
  const isAuthenticated = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.userInfo);
  const userCompanyData = useSelector((state) => state.auth.userCompanyInfo);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const profileLink =
    user?.usertype === "Owner" ? "/profile" : "/subuserprofile";
  const logout = useCallback(() => {
    dispatch(authLogout());
    // dispatch(propertyLogout());
    dispatch(namesLogout());
    dispatch(subUserLogout())
    dispatch(clearProperty())
    localStorage.removeItem("persist:auth");
    localStorage.removeItem("persist:property");
    localStorage.removeItem("persist:names");
  }, [dispatch]);

  const { data: photo } = useFetch(
    `/properties/subuserlist/?user_id=${user?.id}`
  );

  const handleDeviceLogout = async () => {
    try {
      await fetch(`${BASE_URL}/properties/device_logout_update`, {
        method: "GET",
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
    } catch (error) {
      toast.error("Error during logged In ");
    }
  };

  const handlelogOut = () => {
    logout();
    console.log("logout on clicking button");

    handleDeviceLogout();
  };

  const logoutAllDevices = async (e) => {
    e.preventDefault();
    // setEditLoading(true);

    try {
      const responst = await fetch(
        `${BASE_URL}/properties/selflogout_alldevices/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      );
      const data = await responst.json();
      if (responst.ok) {
        toast.success(data.detail);

        logout();
        console.log("logtout from all device api ");

        handleDeviceLogout();
      } else {
        // console.log(data.error);

        toast.error("Error logging out ");
      }
    } catch (error) {
      toast.error("Error logging out ");
    } finally {
      // setEditLoading(false)
    }
  };

  useEffect(() => {
    if (!token || !refreshToken) return;
  
    let timeout; // Declare timeout variable
    let interval; // Declare interval variable
  
    const handleTokenRefresh = async () => {
      try {
        if (refreshToken) {
          const { exp: refreshExp } = jwtDecode(refreshToken);
          const currentTime = Date.now() / 1000;
          const refreshThreshold = 30 * 60; // 30 minutes in seconds
  
          if (refreshExp - currentTime < refreshThreshold) {
            alert("Your session has expired. Please login again!");
            dispatch(logout());
            setTimeout(() => {
              window.location.reload()
            }, 500);
            return;
          }
        } else {
          console.error("Invalid refresh token");
          dispatch(logout());
          return;
        }
  
        const response = await fetch(`${BASE_URL}/auth/jwt/refresh/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refresh: refreshToken }),
        });
  
        if (response.ok) {
          const data = await response.json();
          dispatch(refreshAuthToken(data.access));
        } else {
          console.error("Token refresh failed:", await response.json());
          dispatch(logout());
        }
      } catch (error) {
        console.error("Token refresh failed:", error);
        dispatch(logout());
      }
    };
  
    const checkTokenExpiration = () => {
      if (!token || !refreshToken) {
        dispatch(logout());
        return;
      }
  
      const { exp: accessExp } = jwtDecode(token);
      const { exp: refreshExp } = jwtDecode(refreshToken);
      const currentTime = Date.now() / 1000;
      const refreshThreshold = 10 * 60;
  
      if (refreshExp < currentTime || (refreshExp - currentTime) < refreshThreshold) {
        alert("Your session has expired. Please login again!");
        dispatch(logout());
        setTimeout(() => {
          window.location.reload()
        }, 500);
        return;
      }
  
      if (accessExp - currentTime < 90) {
        // Access token expires in less than 1 minute, refresh it
        handleTokenRefresh();
      }
    };
  
    const setDynamicInterval = () => {
      if (!token || !refreshToken) {
        dispatch(logout());
        return;
      }
      const { exp: accessExp } = jwtDecode(token);
      const { exp: refreshExp } = jwtDecode(refreshToken);
      const currentTime = Date.now() / 1000;
      const timeLeft = accessExp - currentTime;
  
      if (timeLeft <= 0 && refreshExp < currentTime ) {
        alert("Your session has expired. Please login again!");
        dispatch(logout());
        setTimeout(() => {
          window.location.reload()
        }, 500);
        return;
      }
  
      const firstInterval = Math.max((timeLeft - 60) * 1000, 0); // 1 minute before expiration
      const standardInterval = 240000; // 4 minutes
  
      // Set first interval to check token expiration
      timeout = setTimeout(() => {
        checkTokenExpiration();
  
        // After the first interval, set a regular 4-minute interval
        interval = setInterval(checkTokenExpiration, standardInterval);
      }, firstInterval);
    };
  
    setDynamicInterval();
  
    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [token, refreshToken, dispatch, logout]);
  
  
 

  return (
    <>
      <div className="px-2 py-2 md:px-10 md:py-3 bg-gray-5 flex items-center justify-between bg-[#1E9DE4] text-white fixed w-full top-0 z-20">
        <div className="flex items-center gap-3">
          {isAuthenticated && <div
            className="md:hidden cursor-pointer "
            onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
          >
            <FaBars className="w-6 h-6" />
          </div>}

          <Link to="/" className="flex items-center gap-1">
            <img
              src="/images/appLogo.png"
              alt=""
              className="hidden md:flex h-10 w-10 md:w-12 md:h-12 scale-150"
            />
            <span className="md:ml-5 ml-0 font-medium md:text-xl text-xl">
              RealtorApp.io
            </span>
          </Link>
        </div>
        
        
        {isAuthenticated ? (
          <>
            <div className="flex md:gap-3 items-center  relative">
              <Link
                to="/"
                className="font-medium uppercase p-3 hidden md:block hover:bg-white hover:text-black rounded-xl transition-all "
              >
                Home
              </Link>
              <Link
                to="/searchProperty"
                className="hidden md:block font-medium uppercase p-2 md:p-3  hover:bg-white hover:text-black rounded-xl transition-all"
              >
                Properties
              </Link>
              <Link
                to="/projects"
                className="font-medium uppercase p-3 hidden md:block hover:bg-white hover:text-black rounded-xl transition-all"
              >
                Projects
              </Link>
              <Link
                // to="/inquiryFilter"
                to="/searchInquiry"
                className="font-medium uppercase p-3 hidden md:block hover:bg-white hover:text-black rounded-xl transition-all"
              >
                Inquiries
              </Link>
              {(user?.usertype === "Owner" ||
                user?.usertype === "Co-Owner") && (
                <Link
                  to="/contact"
                  className="font-medium uppercase p-3 hidden md:block hover:bg-white hover:text-black rounded-xl transition-all"
                >
                  Contacts
                </Link>
              )}
              <Link
                to="/loans"
                className="font-medium uppercase p-3 hidden md:block hover:bg-white hover:text-black rounded-xl transition-all"
              >
                Loans
              </Link>

              <div className="mr-2 md:mr-0">
                <GeneralAlert />
              </div>
              <div
                className="flex items-center gap-2 cursor-pointer"
                onMouseEnter={() => setDropdownVisible(true)}
                onMouseLeave={() => setDropdownVisible(false)}
                onClick={() => setDropdownVisible(!isDropdownVisible)}
              >
                <div className="flex items-center gap-2">
                  {user?.usertype === "Owner" ? (
                    userCompanyData?.profile_photo ? (
                      <img
                        src={`${userCompanyData.profile_photo}`}
                        alt=""
                        className="w-12 h-12 object-cover rounded-[50%]"
                      />
                    ) : (
                      <FaUserCircle className="w-10 h-10" />
                    )
                  ) : photo?.results?.length > 0 &&
                    photo?.results[0]?.subuser_profile_photo ? (
                    <img
                      src={`${photo?.results[0]?.subuser_profile_photo}`}
                      alt=""
                      className="w-12 h-12 object-cover rounded-[50%]"
                    />
                  ) : (
                    <FaUserCircle className="w-10 h-10" />
                  )}
                </div>


                {isDropdownVisible && (
                  <div className="absolute -right-2 text-xl md:text-base md:right-5     top-5  mt-2  bg-white  text-black border flex flex-col  rounded shadow-md" >
                    <p className="py-2 px-7  bg-slate-200 sticky">{user?.username}</p>

                    <Link
                      to={profileLink}
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Profile
                    </Link>
                    {(user?.usertype === "Owner" ||
                      user?.usertype === "Co-Owner") && (
                      <Link
                        to="/subuser "
                        className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                      >
                        Subuser
                      </Link>
                    )}
                    {(user?.usertype === "Owner" ||
                      user?.usertype === "Co-Owner") && (
                      <Link
                        to="/userdevice "
                        className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                      >
                        User Device
                      </Link>
                    )}

                    <Link
                      to="/paymentdetail"
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Subscription
                    </Link>
                    <Link
                      to="/arealist "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Areas List
                    </Link>
                    <Link
                      to="/societyList "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Society List
                    </Link>
                    <Link
                      to="/sources "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Source List
                    </Link>
                    <Link
                      to="/contactgroup "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Contact Groups
                    </Link>
                    <Link
                      to="/tasks "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Tasks
                    </Link>
                    {(user?.usertype === "Owner" ||
                      user?.usertype === "Co-Owner") && (
                      <Link
                        to="/deals "
                        className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                      >
                        Deals
                      </Link>
                    )}
                    <Link
                      to="/commission "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Commission
                    </Link>
                    {/* <Link
                      to="/99acres "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      99acres
                    </Link> */}
                    <Link
                      to="/bulkproperty "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Bulk Property
                    </Link>
                    <Link
                      to="/bulkinquiry "
                      className="py-2 px-7  hover:bg-gray-100 hover:font-bold"
                    >
                      Bulk Inquiry
                    </Link>
                    <button
                      className="block w-full text-left py-2 px-7  hover:bg-gray-100 hover:font-bold"
                      onClick={handlelogOut}
                    >
                      Logout
                    </button>

                    <button
                      className="block w-full text-left py-2 px-7  hover:bg-gray-100 hover:font-bold"
                      onClick={logoutAllDevices}
                    >
                      Logout All Devices
                    </button>
                  </div>
                )}
              </div>

                {/* for mobile devices  */}
              <div>
                
                <Sidebar isMobileMenuOpen={isMobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
              </div>
            </div>
          </>
        ) : (
          <div className="flex md:gap-5 gap-2 items-center">
<Link to={"/offers"} className=" h-fit flex md:gap-3  gap-1 text-[10px] items-center cursor-pointer uppercase  shadow-black  text-black bg-gradient-to-br from-yellow-50 to-yellow-200 px-4 py-2   rounded-full font-semibold md:text-sm shadow-sm hover:bg-[#FFC107] transition-transform transform ">
         Pricing
</Link>
            <p className="hidden md:flex items-center gap-3  p-2 rounded-xl text-white">
              <span>
                <IoCall className="w-6 h-6" />
              </span>
              <span className="text-lg font-semibold">+91 9327055500</span>
            </p>
            {location?.pathname !== "/login" ? (
              <div className=" md:flex md:gap-3 ">
                <Link
                  to="/login"
                  className="font-medium uppercase p-2 md:px-3 border-2 border-white hover:text-black  hover:bg-white  md:rounded-xl rounded-lg  transition-all"
                >
                  Login
                </Link>
              </div>
            ) : (
              <div className=" md:flex md:gap-3 ">
                <Link
                  to="/register"
                  className="font-medium uppercase p-2 md:px-3 border-2 border-white hover:text-black  hover:bg-white  md:rounded-xl rounded-lg  transition-all"
                >
                  Sign Up
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
