import { useEffect, useState } from "react"
import { BASE_URL } from "../util/Api"
// import { useSelector } from "react-redux"

const useFetchWithoutToken = (url)=>{
    const [data, setData] = useState("")
    const [loading, setLoading] = useState("")
    const [error, setError] = useState("")
    // const token = useSelector(state=>state.auth.accessToken)

    useEffect(()=>{
        setLoading(true)
        // console.log("Fetching data from:", url);
        fetch(`${BASE_URL}${url}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json(); // Parse JSON data
            })
            .then((data) => {
                setData(data);
                setLoading(false);
            })
            .catch((err) => {
                setError(err.message || "Something went wrong!");
                setLoading(false);
            });
    },[url])
    return {data, loading, error}

}

export default useFetchWithoutToken;



