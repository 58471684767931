// export   const BASE_URL = "http://127.0.0.1:8000"
import { toast } from "react-toastify";
import store from "../redux/Store"

export const BASE_URL = process.env.REACT_APP_BASE_URL;


const getAccessToken = () => {
    const state = store.getState();
    return state.auth.accessToken; 
  };


export const getRequest = async (endpoint) => {
    const token = getAccessToken()
    try {
        // let Data = {}
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`, 
            },
        });
        const data = await response.json()
        if (!response.ok) {
            throw Error(response.statusText);
        }
        
        return data
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};


export const getApiRequest = async (endpoint) => {
    const token = getAccessToken()
    try {
        // let Data = {}
        const response = await fetch(`${BASE_URL}${endpoint}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });
        const data = await response.json()
        
        if (!response.ok) {
           toast.error(data.details)
        }
        
        return data
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};


// to get all pages data 
export const getPaginationRequest = async (endpoint) => {
    const token = getAccessToken()

    try {
        const fetchAllResults = async (url, accumulatedResults = []) => {
            // Check if the URL is a full URL or a relative path
            const fullUrl = url.startsWith('http') ? url : `${BASE_URL}${url}`;

            const response = await fetch(fullUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${token}`,
                },
            });
            const data = await response.json();

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            // Accumulate results from this page
            accumulatedResults = accumulatedResults.concat(data.results);

            // If there's a next page, fetch the next page recursively
            if (data.next) {
                return fetchAllResults(data.next, accumulatedResults);
            }

            // Return the accumulated results along with other data fields
            return {
                count: data.count,
                next: data.next,
                previous: data.previous,
                results: accumulatedResults,
            };
        };

        const data = await fetchAllResults(endpoint);
        return data;
    } catch (error) {
        console.error('API Error:', error);
        throw error;
    }
};



export const fetchNextPage = async (url) => {
    const token = getAccessToken()
    const response = await fetch(url, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`,
      },
  });
    if (!response.ok) {
      throw new Error("Failed to fetch next page data");
    }
    return response.json();
  };

